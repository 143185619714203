.x-file-preview
  &__figure
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 120px
    border: 1px solid var(--divider-dark-grey)
    border-radius: 4px
    padding: 4px
    background-color: var(--on-surface-dark)
    box-sizing: border-box
    position: relative
    &-image
      width: 100%
      height: 100%
      background-position: center
      background-size: contain
      background-repeat: no-repeat
    button
      display: block
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      border: none
      background: none
  &__caption
    margin-top: 9px
    &-error
      +preset(3)
      color: var(--on-surface-red)

  &[selected] &__figure
    border-color: var(--primary)
    border-width: 2px
