import { Component, Host, h, Prop, Fragment } from '@stencil/core';

@Component({
  tag: 'x-file-preview',
  styleUrl: 'x-file-preview.sass',
})
export class XFilePreview {
  @Prop() loading: boolean = false;
  @Prop() selectable: boolean = false;
  @Prop({ reflect: true }) selected: boolean = false;
  @Prop() preview: string = '';
  @Prop() download: string = '';
  @Prop() label: string = '';
  @Prop() error: string = '';

  render() {
    return (
      <Host class="x-file-preview">
        <div class="x-file-preview__figure">
          <div class="x-file-preview__figure-image" style={!!this.preview ? { backgroundImage: `url(${this.preview}` } : null}></div>
          {this.selectable && <button type="button"></button>}
          {this.loading && <x-loader></x-loader>}
        </div>
        <div class="x-file-preview__caption">
          <x-stack direction="row" gap="2xs">
            <x-typography variant="body3" word-break="all">
              File_name_123.pdf
            </x-typography>
            <x-divider direction="vertical" size="s"></x-divider>
            {this.loading && <x-loader></x-loader>}
            {this.loading || this.error ? (
              <x-icon-button color="red">
                <x-icon glyph="delete" size="16"></x-icon>
              </x-icon-button>
            ) : (
              <Fragment>
                {!!this.download ? (
                  <x-icon-button color="dark-50" href={this.download}>
                    <x-icon glyph="download" size="16"></x-icon>
                  </x-icon-button>
                ) : (
                  <x-icon-button color="dark-50">
                    <x-icon glyph="permission-delete" size="16"></x-icon>
                  </x-icon-button>
                )}
              </Fragment>
            )}
          </x-stack>
          {!!this.error && <div class="x-file-preview__caption-error">{this.error}</div>}
        </div>
      </Host>
    );
  }
}
